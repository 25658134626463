@font-face {
	font-family: 'bophloi';
	src: url('bophloi.eot');
	src: url('bophloi.eot') format('embedded-opentype'),
		url('bophloi.ttf') format('truetype'),
		url('bophloi.woff') format('woff'),
		url('bophloi.svg') format('svg');
	font-display: block;
}

.bophloi::before {
	font-family: 'bophloi' !important;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	text-transform: none;
	line-height: 1;
	display: block;
	
	--home: "\e900";
	--home-solid: "\e901";
	--product: "\e902";
	--product-solid: "\e903";
	--promotion: "\e904";
	--promotion-solid: "\e905";
	--cart: "\e906";
	--cart-solid: "\e907";
	--order: "\e908";
	--order-solid: "\e909";
	--sign-in: "\e90a";
	--sign-in-solid: "\e90b";
	--lock: "\e90c";
	--lock-solid: "\e90d";
	--user: "\e90e";
	--user-solid: "\e90f";
	--member: "\e910";
	--member-solid: "\e911";
	--cat: "\e912";
	--cat-solid: "\e913";
	--favorite: "\e914";
	--favorite-solid: "\e915";
	--notification: "\e916";
	--notification-solid: "\e917";
	--search: "\e918";
	--cross: "\e919";
	--left-arrow: "\e91a";
	--right-arrow: "\e91b";
	--up-arrow: "\e91c";
	--down-arrow: "\e91d";
	--filter: "\e91e";
	--filter-bar: "\e91f";
	--filter-line: "\e920";
	--chocolate: "\e921";
	--hamburger: "\e922";
	--exclamation: "\e923";
	--share: "\e924";
	--mail: "\e925";
	--eye: "\e926";
	--eye-closed: "\e927";
}